import firebase from "firebase/app";
import configs from "./configs.json";
import { includes } from "lodash";

const hostName = window.location.hostname;

const hostsProduction = [
  "publicity-28e6f.web.app",
  "anuncia-google-ads.digital",
];

const currentEnvironment = includes(hostsProduction, hostName)
  ? "production"
  : "development";

const currentConfig = configs[currentEnvironment];

firebase.initializeApp(currentConfig.firebaseApp);

const common = configs.common;
const contactData = configs.common.contactData;

const { version, apiUrl, ipInfoApi } = currentConfig;

console.log(currentEnvironment, ":", version);

export {
  version,
  apiUrl,
  ipInfoApi,
  firebase,
  currentConfig,
  common,
  contactData,
};
