export const theme = {
  font_weight: {
    small: "400",
    medium: "500",
    large: "700",
  },
  border_radius: {
    xx_small: ".3em",
    x_small: ".5em",
    small: ".7em",
    medium: ".9em",
    large: "1em",
    x_large: "1.2em",
    xx_large: "1.4em",
    xxx_large: "1.7em",
    percentage_medium: "50%",
    percentage_full: "100%",
  },
  paddings: {
    xx_small: ".3em",
    x_small: ".5em",
    small: ".7em",
    medium: ".9em",
    large: "1em",
    x_large: "1.2em",
    xx_large: "1.4em",
    xxx_large: "1.7em",
  },
  font_sizes: {
    xxx_small: ".6em",
    xx_small: ".7em",
    x_small: ".9em",
    small: "1em",
    medium: "1.1em",
    large: "1.3em",
    x_large: "1.4em",
    xx_large: "1.6em",
    xxx_large: "1.8em",
  },
  colors: {
    primary: "#0F1920",
    secondary: "rgb(255, 255, 255)",
    tertiary: "rgb(255, 255, 255)",
    quaternary: "rgb(43, 49, 57)",
    quinary: "#474D57",
    sextiary: "#D9A23D",

    font1: "rgb(5, 12, 17)",
    font2: "rgb(183, 189, 198)",
    font3: "rgb(132, 142, 156)",

    success: "#0ECB81",
    info: "#0795FF",
    warning: "#F5C203",
    error: "rgb(246, 70, 93)",

    black: "#090B0D",
    white: "#EAECEF",
    dark: "#090B0D",
    light: "#d7d8d9",

    rgba: "rgb(43, 49, 57)",
    gray: "rgb(121, 131, 140)",
  },
};
